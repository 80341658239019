"use client"

import { useEffect } from "react";

export default function TawkWidget() {
    useEffect(() => {
        var Tawk_API: any=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/66607c529a809f19fb395795/1hvkeb8s2';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s0?.parentNode?.insertBefore(s1,s0);
        })();
    }, [])

    return (
        <div>
        </div>
    )
}
